<template>
  <div class="section sectionLoading">
    <div class="container-fluid section-flex-loading">
      <Loading />
      <p class="text-center mt-2 small text-dark">{{ text }}</p>
    </div>
  </div>
</template>
<script>
import Loading from "./Loading";
export default {
  components: {
    Loading,
  },
  props: {
    text: {
      type: "",
      required: false,
    },
  },
};
</script>

<style scoped>
.section-flex-loading {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
