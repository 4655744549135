<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="
      background: rgb(255, 255, 255, 0);
      display: block;
      shape-rendering: auto;
    "
    :width="size"
    :height="size"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      r="27"
      stroke-width="8"
      :stroke="color"
      stroke-dasharray="42.411500823462205 42.411500823462205"
      fill="none"
      stroke-linecap="round"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1.0526315789473684s"
        keyTimes="0;1"
        values="0 50 50;360 50 50"
      ></animateTransform>
    </circle>
  </svg>
</template>

<script>
export default {
  name: "Loading",
  props: {
    size: {
      type: String,
      default: "40px",
    },
    color: {
      type: String,
      default: "#f55b00",
    },
  },
};
</script>
