<template>
    <div class="pb-5 mb-2">
        <div class="d-flex align-items-center mb-4">
            <div class="col-md-6">
                <div class="flex">
                    <a @click="$router.back()" class="text-dark d-flex align-items-center cursor-pointer">
                        <span class="d-flex align-items-center">
                            <vs-icon icon="chevron_left"></vs-icon>
                        </span>
                        <span class="text-underline">Back</span>
                    </a>
                </div>
            </div>
            <div class="col-md-6">
                <div class="text-right" v-if="!loading && releaseData.id && isReleaseOwner">
                    <button @click="deleteRelease()"
                        class="text-primary border py-2.5 mr-3 border-primary rounded-sm
                         text-sm px-4 outline-none  focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                        <span> Delete </span>
                    </button>

                    <router-link :to="`/edit-release/${$route.params.id}`"
                        class="text-primary border py-2.5 mr-3 border-primary rounded-sm
                         text-sm px-4 outline-none  focus:text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2">
                        <span> Edit release </span>
                    </router-link>

                    <!-- <a v-if="releaseData.resource_url" :href="releaseData.resource_url" target="_blank"
                        class="btn btn-primary btn-site px-3">
                        <span style="display: flex">
                            <vs-icon icon="language" size="16px"></vs-icon>
                            <span> View Release </span>
                        </span>
                    </a> -->
                </div>
            </div>
        </div>
        <SectionLoading v-if="loading" text="Release loading..." />
        <div v-else-if="releaseData.id">
            <div class="row">
                <div class="col-md-3">
                    <div class="download-art">
                        <img class="img-fluid albumImg w-full" :src="releaseData.cover_image_path || ''"
                            :alt="releaseData.title" />
                        <a :download="releaseData.title" :href="releaseData.cover_image_path" :title="releaseData.title"
                            target="_blank">
                            <vs-icon icon="download"></vs-icon>
                        </a>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="releaseData">
                        <p class="type mt-4 md:mt-0">{{ releaseData.type }}</p>
                        <div>
                            <h1 class="text-black font-bold text-3xl md:text-6xl mb-3 hover-copy d-inline-block"
                                @click="copyItem(releaseData.title)">
                                {{ releaseData.title }}
                            </h1>
                        </div>
                        <p class="mb-1" v-if="releaseData.user">
                            <span>{{ releaseData.user.artist_name }}</span><span class="text-dark"> ● {{ releaseData.label
                            }}
                            </span>
                        </p>
                        <p class="releaseinf">
                            <span class="titl">DATE ADDED: </span><span class="text-dark">{{
                                moment
                                    .utc(new Date(releaseData.created_at))
                                    .format("DD/MM/YYYY")
                            }}</span>
                        </p>
                        <p class="releaseinf">
                            <span class="titl">EXPECTED RELEASE DATE: </span><span class="text-dark">
                                {{ releaseData.release_date?.length > 10 ? moment
                                    .utc(releaseData.release_date)
                                    .format("DD/MM/YYYY") :
                                    releaseData.release_date
                                }}


                            </span>
                        </p>
                        <p class="releaseinf">
                            <span class="titl">STATUS: </span><span class="text-dark">{{
                                releaseData.status | capitalize
                            }}</span>
                        </p>

                    </div>
                </div>
            </div>

            <div class="card siteCard mt-3">
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Title</th>
                                <th scope="col">Artist</th>
                                <th scope="col">Writers</th>
                                <th scope="col">Creators</th>
                                <th scope="col">Recording Year</th>
                                <th scope="col">Stores</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(release, index) in releaseData.tracks" :key="index">
                                <td>
                                    <p class="text-dark mb-0">{{ index + 1 }}</p>
                                </td>

                                <td>
                                    <div class="playBtn" @click="isReleaseOwner ? downloadAudioFile(release.audio_path, release.id) : ''">
                                        <img v-if="$store.getters.audioPlay.status &&
                                            $store.getters.audioPlay.id == release.id
                                            " src="/images/play.svg" height="20" alt="" />
                                            <span v-else>
                                                <vs-icon v-if="isReleaseOwner" icon="play_circle_filled "></vs-icon>
                                            </span>
                                    
                                        <div class="ml-2">
                                            <span class="text-dark mb-0 title">{{
                                                release.title
                                            }}</span>
                                            <div>
                                                <span class="explicit" v-if="release.explicit_content == 'Yes'"
                                                    style="font-size: 12px">Explicit</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p class="creatorList hover-copy" @click="copyItem(release.artist_name)">
                                        <span>
                                            {{ release.artist_name }}
                                        </span>
                                    </p>
                                </td>

                                <td>
                                    <p class="text-dark mb-0 creatorList">
                                        <span v-for="(writer, index) in release.writers" :key="index">{{ writer.name }}
                                            <span v-if="index + 1 < release.writers.length">, </span>
                                        </span>
                                    </p>
                                </td>

                                <td>
                                    <p class="text-dark mb-0 creatorList">
                                        <span v-for="(artist, index) in release.artists" :key="index">{{ artist.name }}
                                            <span>({{ artist.role }})</span>
                                            <span v-if="index + 1 < release.artists.length">, </span>
                                        </span>
                                    </p>
                                </td>

                                <td>
                                    <p class="text-dark mb-0 hover-copy" @click="copyItem(release.year)">
                                        {{ release.year }}
                                    </p>
                                </td>
                                <td>
                                    <p class="text-dark mb-0 creatorList">
                                        <span v-for="(store, index) in release.stores" :key="index">{{ store.name }}
                                            <span v-if="index + 1 < release.stores.length">, </span>
                                        </span>
                                    </p>
                                </td>
                                <td>
                                    <div class="flex space-x-2">
                                        <vs-button v-if="isReleaseOwner" @click="downloadAudioFile(release.audio_path, release.id)"
                                            color="primary" type="flat" icon="file_download" class="mb-2 mr-2"
                                            size="small">Download</vs-button>
                                        <vs-button v-if="release.lyrics" @click="viewLyricsAction(release.lyrics)"
                                            color="primary" type="flat" icon="lyrics" size="small">View Lyrics</vs-button>

                                        <vs-button v-if="release.artists.length > 0"
                                            @click="viewSplitListing(release.artists)" color="primary" type="flat"
                                            icon="percent" size="small">View Splits</vs-button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card siteCard mt-3">
                <p class="breakdown-title">Release Data</p>
                <div class="p-3">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="metaDisplay">
                                <p class="headi">Title</p>
                                <p class="data hover-copy" @click="copyItem(releaseData.title)">
                                    {{ releaseData.title }}
                                </p>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="metaDisplay">
                                <p class="headi">Label</p>
                                <p class="data hover-copy" @click="copyItem(releaseData.label)">
                                    {{ releaseData.label }}
                                </p>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="metaDisplay">
                                <p class="headi">Genre</p>
                                <p class="data hover-copy" @click="copyItem(releaseData.genre)">
                                    {{ releaseData.genre }}
                                </p>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="metaDisplay">
                                <p class="headi">Language</p>
                                <p class="data hover-copy" @click="copyItem(releaseData.language)">
                                    {{ releaseData.language }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="card siteCard mt-3">
                <div class="table-responsive">
                    <table class="table align-items-center table-flush">
                        <thead class="">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Title</th>
                                <th scope="col">Streams</th>
                                <th scope="col">Downloads</th>
                                <th scope="col">Earnings</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(track, index) in releaseData.tracks" :key="index">
                                <td>
                                    <p class="text-dark mb-0">{{ index + 1 }}</p>
                                </td>

                                <td>
                                    <span class="text-dark mb-0 title">{{
                                        track.title
                                    }}
                                    </span>
                                </td>

                                <td>
                                    <p class="text-dark mb-0">
                                        {{ track.stream_count | number("0,0") }}
                                    </p>
                                </td>

                                <td>
                                    <p class="text-dark mb-0">
                                        {{ track.download_count | number("0,0") }}
                                    </p>
                                </td>

                                <td>
                                    <p class="text-dark mb-0">
                                        {{ track.earnings | currency("€", 2) }}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->


            <div class="card siteCard mt-3" v-if="releaseData.tracks">
                <p class="breakdown-title">DSP Breakdown</p>

                <div class="flex flex-col space-y-2">
                    <div v-for="(track, index) in releaseData.tracks" :key="index" class="bg-gray-100 rounded mx-2">
                        <div class="cursor-pointer border-b py-3 px-3" @click="getTrackBreakdown(track?.isrc, index)">
                            <h3 class="font-bold text-black">{{ track.title }}</h3>
                        </div>

                        <div v-if="isDspDisplay[index]">
                            <p v-if="dspLoading" class="text-sm py-4 px-4">Data Loading...</p>

                            <table v-else class="table align-items-center table-flush">
                                <thead class="">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">DSP</th>
                                        <th scope="col">Streams</th>
                                        <th scope="col">Downloads</th>
                                        <th scope="col">Earnings</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(dsp, index) in dspBreakdown" :key="index">
                                        <td>
                                            <p class="text-dark mb-0">{{ index + 1 }}</p>
                                        </td>

                                        <td>
                                            <span class="text-dark mb-0 title">{{
                                                dsp.name
                                            }}
                                            </span>
                                        </td>

                                        <td>
                                            <p class="text-dark mb-0">
                                                {{ dsp.streams | number("0,0") }}
                                            </p>
                                        </td>

                                        <td>
                                            <p class="text-dark mb-0">
                                                {{ dsp.downloads | number("0,0") }}
                                            </p>
                                        </td>

                                        <td>
                                            <p class="text-dark mb-0">
                                                {{ dsp.revenue | currency("€", 2) }}
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card siteCard mt-3">
                <div class="p-3">
                    <div class="row">
                        <div class="col-md-4">
                            <div>
                                <p class="creatorTitle">Total Stream Count</p>
                                <h3 class="my-2">
                                    <span class="text-dark font-weight-bold">{{
                                        releaseData.total_stream_count | number("0,0")
                                    }}</span>
                                </h3>
                            </div>

                            <div>
                                <p class="creatorTitle">Total Downloads</p>
                                <h3 class="my-2">
                                    <span class="text-dark font-weight-bold">{{
                                        releaseData.total_download_count | number("0,0")
                                    }}</span>
                                </h3>
                            </div>

                            <div>
                                <p class="creatorTitle">Total Earnings</p>
                                <h3 class="my-2">
                                    <span class="text-dark font-weight-bold">{{
                                        releaseData.earnings | currency("€", 2)
                                    }}</span>
                                </h3>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <v-chart class="chart" :option="infoChart" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="card siteCard mt-3">
                <div class="p-3">
                    <v-chart class="trackChart" :option="trackChart" />
                </div>
            </div> -->
        </div>

        <vs-popup class="updateModal" title="Lyrics" :active.sync="lyricsView">
            <p class="lyricsText hover-copy" @click="copyItem(lyricsData)">
                {{ lyricsData }}
            </p>
        </vs-popup>

        <vs-popup class="updateModal" title="Split" :active.sync="splitView">
            <div class="flex flex-col space-y-6">
                <div class="border-b border-dark-300 pb-4" v-for="(split, index) in splitData" v-if="split.split_percentage"
                    :key="index">
                    <div class="w-full">
                        <h2 class="text-lg font-semibold">{{ split.name }}</h2>
                        <p class="text-gray-500 mb-1 text-sm">{{ split.email }}</p>
                        <span class="text-gray-600 text-xs">Split
                            Percentage: <span class="font-bold">
                                {{ split.split_percentage }}%
                            </span></span>
                        <div class="mt-2 relative">
                            <div class="w-full bg-green-500 h-1 rounded absolute"
                                :style="`width: ${split.split_percentage}%`"></div>
                            <div class="w-full bg-green-400 h-1 rounded opacity-20"></div>
                        </div>
                    </div>
                </div>
            </div>
        </vs-popup>

        <input style="opacity: 0; position: absolute; top: 0; left: 0" type="text" v-model="itemCopy" id="item-copy" />
    </div>
</template>
<script>
import SectionLoading from "@/components/SectionLoading.vue";
import BtnLoading from "@/components/BtnLoading.vue";

export default {
    name: "Release",
    components: {
        SectionLoading,
        BtnLoading,
    },
    data() {
        return {
            loading: false,
            itemCopy: "",
            releaseData: {},
            updateReleaseID: false,
            fugaLoading: false,
            isrcLoading: false,
            lyricsView: false,
            reasonModal: false,
            reasonSelected: "",
            splitView: false,
            splitData: {},
            btnLoading: false,
            lyricsData: "",
            isrc_number: "",
            fuga_id: "",
            isrcView: false,
            activeTrack: {},
            dspBreakdown: [],
            isDspDisplay: [],
            dspLoading: false,
            trackChart: {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                legend: {},
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: {
                    type: "value",
                    boundaryGap: [0, 0.01],
                },
                yAxis: {
                    type: "category",
                    data: [],
                },
                series: [
                    {
                        name: "Downloads",
                        type: "bar",
                        color: "#f88f80",
                        data: [],
                    },
                    {
                        name: "Streams",
                        type: "bar",
                        color: "#f8c9a0",
                        data: [],
                    },
                    {
                        name: "Earnings",
                        type: "bar",
                        color: "#f8f0a0",
                        data: [],
                    },
                ],
            },
            infoChart: {
                tooltip: {
                    trigger: "item",
                },
                legend: {
                    orient: "vertical",
                    left: "left",
                },
                series: [
                    {
                        name: "Release Info",
                        type: "pie",
                        radius: ["40%", "70%"],
                        color: ["#f88f80", "#f8c9a0", "#f8f0a0", "#a0f8a0", "#a0f8f0"],
                        itemStyle: {
                            borderRadius: 5,
                        },
                        data: [],

                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0)",
                            },
                        },
                    },
                ],
            },
        };
    },
    computed: {
        isReleaseOwner() {
            return this.$store.getters.user?.uuid === this.releaseData?.user?.uuid;
        }
    },  
    mounted() {
        this.getReleaseByID();
    },
    methods: {
        deleteRelease() {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm Delete`,
                text: `Are you sure you want to delete this release ?`,
                accept: () => {
                    this.$vs.loading();

                    let fetch = {
                        path: `artist/releases/delete/${this.$route.params.id}`,
                        data: {},
                    };
                    this.$store
                        .dispatch("deleteRequest", fetch)
                        .then((resp) => {
                            this.$toast.success(
                                "Release Delete",
                                "Release has been deleted successfully",
                                this.$toastPosition
                            );

                            this.$vs.loading.close();
                            setTimeout(() => {
                                this.$router.push("/app");
                            }, 1000);
                        })
                        .catch((err) => {
                            this.$vs.loading.close();
                            if (err.response) {
                                this.$toast.info(
                                    err.response.data.message,
                                    "Unable to delete Release",
                                    this.$toastPosition
                                );
                            } else {
                                this.$toast.info(
                                    "Something went wrong",
                                    "Unable to delete release",
                                    this.$toastPosition
                                );
                            }
                        });
                },
            });
        },
        copyReleaseID() {
            if (this.releaseData.fuga_release_id) {
                let copyText = document.getElementById("copy-text");
                copyText.select();
                document.execCommand("copy");

                this.$toast.info(
                    "Release ID" +
                    this.releaseData.fuga_release_id +
                    " has been copied to clipboard",
                    "Release ID",
                    this.$toastPosition
                );
            } else {
                this.$toast.error(
                    "Release ID",
                    "Release ID not found",
                    this.$toastPosition
                );
            }
        },
        getReleaseByID() {
            this.loading = true;
            const uuid = this.$route.params.id;
            let fetch = {
                path: `/artist/releases/single/${uuid}`,
            };
            this.$store
                .dispatch("getRequest", fetch)
                .then((resp) => {
                    this.releaseData = resp.data.data?.releases;

                    this.infoChart.series[0].data = [
                        { value: this.releaseData.total_stream_count, name: "Stream Count" },
                        { value: this.releaseData.total_download_count, name: "Download Count" },
                        { value: this.releaseData.earnings, name: "Earnings" },
                    ];

                    for (let i = 0; i < this.releaseData.tracks.length; i++) {
                        this.trackChart.yAxis.data.push(this.releaseData.tracks[i].title);

                        this.trackChart.series[0].data.push(
                            parseInt(this.releaseData.tracks[i].total_download_count)
                        );

                        this.trackChart.series[1].data.push(
                            parseInt(this.releaseData.tracks[i].total_stream_count)
                        );

                        this.trackChart.series[2].data.push(
                            parseInt(this.releaseData.tracks[i].earnings)
                        );
                    }

                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                    if (err.response) {
                        this.$vs.notify({
                            title: "Release Info",
                            text: err.response.data.message,
                            color: "warning",
                            icon: "error",
                            position: "bottom-center",
                        });
                    } else {
                        this.$vs.notify({
                            title: "Release Info",
                            text: "Unable to get Release",
                            color: "dark",
                            icon: "error",
                            position: "bottom-center",
                        });
                    }
                });
        },

        getTrackBreakdown(isrc, index) {
            let fetch = {
                path: `/artist/tracks-dsp-analytics?isrc=${isrc}`
            };
            this.dspLoading = true;

            // Check if the index is already set, then close it 
            if (this.isDspDisplay[index]) {
                this.isDspDisplay[index] = false;
                return;
            }

            this.isDspDisplay = [];
            this.isDspDisplay[index] = !this.isDspDisplay[index];
          

            this.$store
                .dispatch("getRequest", fetch)
                .then((resp) => {
                    this.dspBreakdown = resp?.data?.data || [];
                    this.dspLoading = false;
                })
                .catch((err) => {
                    this.dspLoading = false;
                }).finally(() => {
                    this.dspLoading = false;
                });
        },
        downloadAudioFile(source) {
            let url = source;
            window.open(url, "_blank");
        },
        playAudio(source, id) {
            this.$store.commit("audioPlay", {
                status: true,
                source,
                id,
            });
        },
        copyItem(item) {
            this.itemCopy = item;
            setTimeout(() => {
                let copyText = document.getElementById("item-copy");
                copyText.select();
                document.execCommand("copy");
            }, 1000);

            this.$toast.info("Item copied to clipboard", "", {
                position: "topCenter",
            });
        },
        viewLyricsAction(item) {
            this.lyricsView = true;
            this.lyricsData = item;
        },
        viewSplitListing(item) {
            this.splitView = true;
            this.splitData = item;
        },
    },
};
</script>
<style lang="scss">
.albumImg {
    border-radius: 10px;
}

.releaseData {
    .type {
        color: black;
        text-transform: uppercase;
        margin-bottom: 10px;
        letter-spacing: 2px;
    }

    .title {
        font-size: 5em;
        line-height: 1;
        font-weight: bold;
        color: black;
    }
}

.metaDisplay {
    .headi {
        text-transform: uppercase;
        font-size: 12px;
        color: black;
        letter-spacing: 1px;
        border-radius: 26px;
        margin: 0;
    }

    .data {
        color: black;
    }
}

.updateModal {
    .vs-popup {
        width: 342px !important;
    }
}

.chart {
    width: 100%;
    height: 300px;
}

.trackChart {
    min-height: 300px;
}

.isrc_code {
    font-size: 11px;
    color: black;
    display: inline-block;
    background: #00000024;
    padding: 1px 7px;
    border-radius: 3px;
    margin-top: 7px;
}

.hover-copy {
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
        opacity: 0.3;
        text-decoration: underline;
    }
}

.download-art {
    position: relative;

    .vs-icon {
        cursor: pointer;
        background: #71b682;
        color: white;
        font-size: 17px;
        padding: 7px;
        border-radius: 44px;
        position: absolute;
        top: 15px;
        right: 15px;
        border: 3px solid white;
    }
}

.explicit {
    font-size: 11px;
    display: inline;
    text-transform: uppercase;
    color: #d21d1d;
    border: 2px solid #d21d1d;
    padding: 3px 5px;
    border-radius: 3px;
}

.lyricsText {
    color: black;
    font-size: 14px;
}

.breakdown-title {
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 28px 20px 0px;
    font-weight: bold;
    color: #f88f7f;
}
</style>
  